<template>
  <path
    d="M18 25L18.7172 27.1517C18.8972 27.6915 18.8386 28.2824 18.5563 28.7764L17.4437 30.7236C17.1614 31.2176 17.1028 31.8085 17.2828 32.3483L18 34.5"
    stroke="#3769D6"
    stroke-width="3"
    stroke-linecap="round"
  />
  <path
    d="M24 25L24.7172 27.1517C24.8972 27.6915 24.8386 28.2824 24.5563 28.7764L23.4437 30.7236C23.1614 31.2176 23.1028 31.8085 23.2828 32.3483L24 34.5"
    stroke="#3769D6"
    stroke-width="3"
    stroke-linecap="round"
  />
  <path
    d="M30 25L30.7172 27.1517C30.8972 27.6915 30.8386 28.2824 30.5563 28.7764L29.4437 30.7236C29.1614 31.2176 29.1028 31.8085 29.2828 32.3483L30 34.5"
    stroke="#3769D6"
    stroke-width="3"
    stroke-linecap="round"
  />
  <path
    d="M12 13C12 12.4477 12.4477 12 13 12H35C35.5523 12 36 12.4477 36 13V19C36 20.1046 35.1046 21 34 21H14C12.8954 21 12 20.1046 12 19V13Z"
    fill="#92AFEA"
  />
  <path
    d="M15 17C15 16.4477 15.4477 16 16 16H32C32.5523 16 33 16.4477 33 17C33 18.1046 32.1046 19 31 19H17C15.8954 19 15 18.1046 15 17Z"
    fill="#EDF4FE"
  />
</template>
